import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import style from './LifeAtPermira.module.scss';
import { SmartLink } from '../SmartLink';

const LifeAtPermira = props => {
    const { title, description, image, mobileImage, cta } = props;

    const isMobile = useMediaQuery('only screen and (max-width: 650px)');

    const useMobileImage = mobileImage?.url.length > 1 ? mobileImage.url : image?.url;

    const mobileAltText = mobileImage.alt ? mobileImage.alt : image.alt;

    return (
        <section>
            {title && (
                <div className={style.lifeAtPermiraBanner}>
                    <div className={style.text}>
                        <div className={[style.container, 'container'].join(' ')}>
                            <h2 className={style.title} dangerouslySetInnerHTML={{ __html: title }} />
                            <p
                                className={style.desc}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                            <div className={style.button}>
                                {cta &&
                                    cta.map(({ link, text }, id) => (
                                        <SmartLink
                                            href={link.url}
                                            // hasArrow
                                            // className='arrow-link'
                                            key={id}
                                        >
                                            {text} <span className='icon-arrow-right' />
                                        </SmartLink>
                                    ))}
                            </div>
                        </div>
                    </div>

                    {/* <div className={style.lifeAtPermiraCopy}>
                        <div className={style.lifeAtPermiraDetails}>
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </div>

                        <div className={style.lifeAtPermiraCta}>
                            {cta.map(({ link, text }, id) =>
                                <SmartLink
                                    href={link.url}
                                    hasArrow
                                    className='arrow-link'
                                    key={id}
                            >
                                    {text}
                                </SmartLink>)}
                        </div>
                    </div> */}
                    <div className={style.media}>
                        <div
                            className={style.banner}
                            style={{
                                backgroundImage: `url(${isMobile ? useMobileImage : image?.url})`,
                                backgroundSize: 'cover',
                            }}
                        >
                            <img
                                className={style.mask}
                                src='/images/people/mask.png'
                                alt={isMobile ? mobileAltText : image.alt}
                            />
                            {/* <img className={style.deco} src='/images/banner/brand.png' /> */}
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

LifeAtPermira.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    mobileImage: PropTypes.object,
    cta: PropTypes.array,
};
export default LifeAtPermira;
