import React from 'react';
import PropTypes from 'prop-types';
import PortfolioCard from '../Cards/portfolioCard';
import style from './PortfolioList.module.scss';
const PortfolioList = props => {
    const { portfolios } = props;
    // const filterNames = filters?.map(type =>
    //     type.value.map(value => [type.type, value.value].join('-')).join(' ')
    //   )
    //   .join(' ');
    return (
        <>
            <div className={style.content}>
                <div className={style.items}>
                    {portfolios.data.map((item, id) => (
                        <div className={style.card} key={id}>
                            <PortfolioCard {...item} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

PortfolioList.propTypes = {
    portfolios: PropTypes.object,
};

export default PortfolioList;
